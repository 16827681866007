import { 
  faHtml5, 
  faCss3, 
  faJs, 
  faReact,
  faAngular,
  faVuejs,
  faNodeJs,
  faPython,
  faAmazon
 } from '@fortawesome/free-brands-svg-icons';

 import { 
  faRocket,
  faLeaf,
  faDatabase
 } from '@fortawesome/free-solid-svg-icons';

const skills = [
  { name: 'HTML', icon: faHtml5 },
  { name: 'CSS', icon: faCss3 },
  { name: 'JavaScript', icon: faJs },
  { name: 'React', icon: faReact },
  { name: 'Angular', icon: faAngular },
  { name: 'Vue', icon: faVuejs },
  { name: 'Node', icon: faNodeJs },
  { name: 'Python', icon: faPython },
  { name: 'Django', icon: faRocket },
  { name: 'MongoDB', icon: faLeaf },
  { name: 'PostgreSQL / MySQL', icon: faDatabase },
  { name: 'S3 - EC2 - Lambda - DynamoDB', icon: faAmazon },
];

export default skills